import React from 'react'
import HOC from '../HOC/HOC'
import { images } from '../../assets'

function MemeSection() {
  return (
    <HOC>
        <div className='my-8 mb-16' id='tokenomics'>
            <div className='flex justify-center items-center flex-col' id="clock">
              <h2 className='text-white text-3xl mb-8 text-center time'>
              SnowMan is ready to give back to the <span className='font-bold'>community</span>
              </h2>
              {/* <img src={images.nft[0]} alt={images.nft[1]} className="" oncontextmenu="return false;"/> */}
            </div>
        </div>
    </HOC>
  )
}

export default MemeSection