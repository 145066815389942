import React, {useEffect} from 'react';

import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import AOS from "aos";

import './App.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Swap from './pages/Swap'
// import AnimCursor from './pages/components/AnimCursor';

import { DataContext } from './context/data-context';
import { useData } from './hooks/data-hook';


import GoogleAnalytics from './GoogleAnalytics'


import 'antd/dist/antd.min.css';
import 'aos/dist/aos.css';

function App() {

    useEffect(() => {
        AOS.init({duration: 2000});
        AOS.refresh();
        
    }, []);

    const {address, updateData} = useData()

    let routes;

    routes = (
      <Routes>
         
          <Route path='/' exact element={<Swap/>}/>
          <Route path="*" element={<Navigate to ="/" />}/>
      </Routes>
  );


    return (
        <DataContext.Provider
            value={{
            wallet: address,
            logout: updateData
        }}>
          
          <div className='app__background '>
          
            <Router>
            <GoogleAnalytics/>

                
                <div className='relative'>
                  {routes}
                </div>
                

            </Router>
            
          </div>
        </DataContext.Provider>
    );
}

  export default App;