
import React, { useReducer, useState } from 'react';
import Header from '../default/Header';
import Countdown from 'react-countdown';

import Timer from  './components/timer/Timer'

import './Swap.css'
import SuccessModal from './components/success/SuccessModal';

import moment from 'moment'
import MemeSection from './sections/MemeSection';
import Snowfall from './sections/Snowfall';

const modalReducer = (state, action) => {
    switch (action.type) {
      case "true":
        return { showModal: true};
      case "false":
        return { showModal: false};
      default:
        return state;
    }
  };

function Swap() {
    
    const [walletAddress, setwalletAddress] = useState("")

    const redirectToTwitter = (e) => {
        e.preventDefault()
        const message = `I just joined @Snowman waitlist `
        const encodedMessage = encodeURIComponent(message);
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}`;
        if(walletAddress !== ""){
            window.open(twitterUrl, '_blank');
        }
        else{
            alert("Please write a valid wallet to proceed")
        }
        console.log(state.showModal)
      };

    const handleWalletChange = (event) => {
        setwalletAddress(event.target.value);
      };

    const handleOpenModal = (e) => {
        e.preventDefault()
        if(walletAddress !== ""){
            dispatch({ type: "true"})

        }
    }

      
    const initialState = { showModal: false };
    const [state, dispatch] = useReducer(modalReducer, initialState);

    
    const targetDate = moment('2023-12-24 19:00:00');
    const timeDifference = targetDate.diff(moment());

    return (
        <div className='overflow-hidden '>
        <SuccessModal show={state.showModal} onCancel={() => dispatch({ type: "false"})} />
        <Header/>
        <Snowfall></Snowfall>
        <div className='relative w-full py-4 lg:py-8 px-8  md:px-16 background__h grid gap-4 grid-cols-1 '>
            <div className='z-10 pt-8 '>
             <div data-aos="fade-up" className='my-auto  '>

                {/* <section className="text-wrapper">
                    <div className="text-top">Snowman</div>
                    <div className="text-bottom" aria-hidden="true">Snowman</div>
                </section> */}

                <h1 className='snow-text text-6xl lg:text-9xl'>Snowman</h1>
                
             </div>
            </div>
            {
                // console.log("countdown => ",timeDifference)
            }
            <div className="my-4">
            <Countdown
                date={Date.now() + timeDifference}
                daysInHours={true}
                // controlled={true}
                renderer={Timer}
                
            />
                
            </div>
            
            <div className='z-10 mx-auto '>
                
            <div className="login-box ">
            <h2 className='text-2xl md:text-4xl'>Join the waitlist</h2>

            <div className="flex justify-center items-center mb-4 flex-wrap gap-3 text-center">
                <a href='https://x.com/Snowl_man?t=Bg3weXej0FBryICjBsi_Mw&s=09' target="_blank" rel="noreferrer" 
                className="text-white text-xl mx-3">Follow Snowman on Twitter </a>
                <a href='https://x.com/Snowl_man?t=Bg3weXej0FBryICjBsi_Mw&s=09' target="_blank" rel="noreferrer" 
                className="text-white text-xl px-3 border-l-2 border-r-2">Like this Tweet</a>
                <a href='https://x.com/Snowl_man?t=Bg3weXej0FBryICjBsi_Mw&s=09' target="_blank" rel="noreferrer" 
                className="text-white text-xl mx-3">Retweet this Tweet </a>
            </div>
            <form>

                <div className="user-box">
                    <input type="text" name="" required={true} onChange={(e) => {

                        handleWalletChange(e)
                        
                        }} />
                    <label>Wallet Address</label>
                </div>
                <button onClick={(e) => {
                    handleOpenModal(e)
                    redirectToTwitter(e)
                }}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Submit
                </button>

            </form>
            </div>

            </div> 

            

            
        </div>

        <MemeSection/>

        {/* <Footer/> */}
        </div>
    )
}

export default Swap