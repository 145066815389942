export const navItems = [
  // {
  //   id: 1,
  //   title: "Play",
  //   path: "https://lamb-club.vercel.app/",
  //   nName: "nav-item",
  //   sName: "sidebar-item"
  // },
  // {
  //   id: 2,
  //   title: "Game",
  //   path: "/files/the-lamb-club.zip",
  //   nName: "nav-item",
  //   sName: "sidebar-item"
  // },
  {
    id: 3,
    title: "Telegram",
    path: "https://t.me/+Prqs59wArfYwNzU0",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 4,
    title: "Twitter",
    path: "https://x.com/Snowl_man?t=Bg3weXej0FBryICjBsi_Mw&s=09",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  // {
  //   id: 6,
  //   title: "Docs",
  //   path: "https://lamb-racing.gitbook.io/lamb-racing/",
  //   nName: "nav-item",
  //   sName: "sidebar-item"
  // },
  // {
  //   id: 6,
  //   title: "Buy LAMB",
  //   path: "https://lamb-racing.gitbook.io/lamb-racing/",
  //   nName: "nav-item",
  //   sName: "sidebar-item"
  // },
];
