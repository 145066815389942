import React from 'react'
import moment from 'moment'
import './Timer.css'
function Timer({days, hours, minutes, seconds, completed }) {
    const formattedDate = moment('2023-12-24').format('YYYY-MM-DD ddd');

    // console.log("completed => ",completed)
    // console.log("hours => ",hours)

    if (completed) {
        // Render a completed state
        return <div id="clock">
            <p className='time text-3xl md:text-6xl lg:text-7xl  text-center'>WE ARE LAUNCHED!!!</p>
        </div>
      } else {
        // Render a countdown
        return (
            <div id="clock">
                <p className="date">LAUNCH DATE</p>
                <p className="date">{formattedDate}</p>
                <p className="time text-3xl md:text-6xl lg:text-7xl ">{days}:{hours}:{minutes}:{seconds}</p>
                
            </div>
        );
      }
}

export default Timer