import React from 'react'
import Modal from '../../../UI_Elements/Modal/Modal'
import ParticlesSwarm from './Swarm'



function SuccessModal(props) {

  return (
    <div>
        <Modal show={props.show} onCancel={props.onCancel} >
        <ParticlesSwarm/>
            <div className='px-4 py-2'>
                <h1 className='text-3xl text-primary-thick-black poppins__bold text-center'>Cheer Fellow Snowman!</h1>
                <p className='text-center '>You have successfully joined the waitlist!</p>
            
            </div>
        </Modal>
    </div>
  )
}

export default SuccessModal