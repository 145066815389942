import { useState, useCallback } from 'react'

export const useData = () => {
    const {address, setAddress} = useState(null)

    const updateWallet = useCallback((wallet) =>{
        setAddress(wallet)

        localStorage.setItem(
            'userData',
            JSON.stringify({
              wallet: wallet
            })
        );
        
    }, [setAddress])

    return {address, updateWallet}
}
